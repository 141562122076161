import {EMPTY_TEMPLATE, ServiceProject, ServiceProjectEnabledStatus, ServiceProjectStatus} from "../../domain/Service";
import {httpDelete, httpGetJSON, httpPostEmpty, httpPostJSON, httpPutFile, httpPutJSON, signUpToken} from "./Fetch";
import {appConfig, customerPrefix} from "../cfg/config";
import {conditionToQueryString} from "./QueryString";
import {TEMPLATES_MAP} from "./TemplatesSet";
import {Converter} from "./Converter";
import {ChangeOptions, GetCondition, ListItemApi} from "./ListItemApi";

interface ProjectApiObject {
  id: string;
  name: string;
  owner: string;
  did?: string;
  description: string;
  shortDescription: string;
  logo: string;
  banner: string;
  categories: string[];
  countries: string[];
  keywords: string[];
  status: ServiceProjectStatus;
  paymentPlan?: string;
  addons?: string[];
  onPrem?: boolean;
  templateName?: string;
  enabledStatus: ServiceProjectEnabledStatus;
  isPublic: boolean;
}

class ProjectConverter extends Converter<ServiceProject, ProjectApiObject> {
  apiObjectToItem(obj: ProjectApiObject, parent?: undefined): ServiceProject {
    const item: ServiceProject = {...obj, identity: obj.id, paymentPlan: obj.paymentPlan};
    if (obj.logo) {
      item.logo = `${appConfig().ZAKA_BACKEND}${obj.logo}`;
    }
    if (obj.banner) {
      item.banner = `${appConfig().ZAKA_BACKEND}${obj.banner}`;
    }
    if (obj.templateName) {
      item.template = TEMPLATES_MAP.get(obj.templateName) || EMPTY_TEMPLATE;
    } else {
      item.template = EMPTY_TEMPLATE;
    }
    return item;
  }

  itemToApiObject(item: ServiceProject): ProjectApiObject {
    const res: ProjectApiObject = {...item, id: item.identity, paymentPlan: item.paymentPlan};
    if (item.template && item.template !== EMPTY_TEMPLATE) {
      res.templateName = item.template.name;
    }
    delete (res as any).template;
    return res;
  }
}

const converterObject = {
  identity: 'id',
};

export class ProjectApi extends ListItemApi<ServiceProject, ProjectApiObject> {
  constructor() {
    super(new ProjectConverter());
  }

  async checkName(name: string) {
    // return {ok: true};
    return httpGetJSON(`${customerPrefix()}/project/check/${encodeURI(name)}`, signUpToken());
  }

  async sendToReview(project: ServiceProject) {
    const prj = await httpPostEmpty(`${customerPrefix()}/to-review/${encodeURI(project.identity)}`, signUpToken());
    return this.converter.apiObjectToItem(prj);
  }

  async updateLogo(service: ServiceProject, file: File) {
    if (!file) {
      return;
    }
    await httpPutFile(`${customerPrefix()}/project/${encodeURI(service.identity)}/logo`, file, 'logo', signUpToken());
  }

  async updateBanner(service:ServiceProject, file: File) {
    if (!file) {
      return;
    }
    await httpPutFile(`${customerPrefix()}/project/${encodeURI(service.identity)}/banner`, file, 'banner', signUpToken());
  }

  async disable(project: ServiceProject) {
    const did = project.did;
    if (!did) {
      throw new Error('Unable to disable not approved service');
    }
    await httpPostEmpty(`${customerPrefix()}/disable/${encodeURI(did)}`, signUpToken());
  }

  async enable(project: ServiceProject) {
    const did = project.did;
    if (!did) {
      throw new Error('Unable to enable not approved service');
    }
    await httpPostEmpty(`${customerPrefix()}/enable/${encodeURI(did)}`, signUpToken());
  }

  protected async fetchOne(condition?: GetCondition): Promise<ProjectApiObject> {
    const id = condition && condition.identity ? condition.identity : '';
    return await httpGetJSON(`${customerPrefix()}/project/${encodeURI(id as string)}`, signUpToken());
  }

  protected async fetchList(condition?: GetCondition) {
    // return {itemsTotal: FAKE_PROJECTS.length, items: FAKE_PROJECTS};
    const resp = await httpGetJSON(
      `${customerPrefix()}/projects${conditionToQueryString(condition, converterObject)}`,
      signUpToken()
    );
    return resp;
  }

  protected async fetchCreate(item: ProjectApiObject): Promise<ProjectApiObject> {
    const d: any = {...item};
    delete d.id;
    const created = await httpPostJSON(`${customerPrefix()}/project`, d, signUpToken());
    return created;
  }

  protected async fetchSave(item: ProjectApiObject, opt?: ChangeOptions): Promise<void> {
    console.log('i call save');
    const prevName = opt ? opt.prevIdentity : item.id;
    await httpPutJSON(`${customerPrefix()}/project/${encodeURI(prevName as string)}`, item, signUpToken());
  }

  protected async fetchDelete(item: ProjectApiObject): Promise<void> {
    await httpDelete(`${customerPrefix()}/project/${encodeURI(item.id)}`, signUpToken());
  }

}
