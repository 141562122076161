import {Identity} from "./Identity";

export enum ServiceProjectStatus {
  DRAFT = 'Draft',
  IN_REVIEW = 'In review',
  REJECTED = 'Rejected',
  ACTIVE = 'Active',
}

/*export enum PaymentPlanType {
  SCHOOLS = '0',
  SMALL_ORGANIZATION = '1',
  BIG_ORGANIZATION = '2',
  TEST_CENTER = '3',
}*/

export enum ServiceProjectEnabledStatus {
  ENABLED = 'ENABLED',
  DISABLED_BY_ADMIN = 'DISABLED_BY_ADMIN',
  DISABLED_BY_USER = 'DISABLED_BY_USER',
}

export interface ServiceBase extends Identity<string> {
  name: string;
  owner: string;
  description: string;
  shortDescription: string;
  logo: string;
  banner: string;
  bannerFile?: File;
  logoFile?: File;
  categories: string[];
  keywords:string[];
  countries: string[];
  isPublic: boolean;
}

export interface ServiceProject extends ServiceBase {
  did?: string;
  paymentPlan?: string;
  addons?: string[];
  onPrem?: boolean;
  status: ServiceProjectStatus;
  template?: ServiceTemplate;
  enabledStatus: ServiceProjectEnabledStatus;
}

export interface Service extends ServiceBase {
  did: string;
  endpoint: string;
  container: string;
  lastTouch: string;
}

export interface ServiceTemplate extends Identity<string> {
  name: string;
  description: string;
}

export interface PaymentPlan extends Identity<string> {
  // type: PaymentPlanType;
  name: string;
  price: number;
  onPremPrice: number;
  paymentPeriod?: string;
  description: string;
  logo?: string;
}

export const EMPTY_TEMPLATE: ServiceTemplate = {identity: 'empty', name: 'empty', description: 'Configures manually'};

/*export const PAYMENT_PLANS: PaymentPlan[] = [
  {identity: 'schools', type: PaymentPlanType.SCHOOLS, name: 'Schools', price: 49, description: 'Schools plan description'},
  {identity: 'checkin', type: PaymentPlanType.SMALL_ORGANIZATION, name: 'Check in', price: 149, description: 'Checkin scenario description'},
  {identity: 'testcenters', type: PaymentPlanType.TEST_CENTER, name: 'Test Center', price: 349, description: 'Test center description'},
];*/

export interface AddOn extends Identity<string> {
  name: string;
  price: number;
  onPremPrice: number;
  description: string;
  isComingSoon: boolean;
  logo?: string;
}

export const createEmptyService = (email: string) => {
  return {
    identity: '',
    name: '',
    shortDescription: '',
    status: ServiceProjectStatus.DRAFT,
    description: '',
    keywords: [],
    categories: [],
    countries: ['*'],
    logo: '',
    banner: '',
    isPublic: false,
    enabledStatus: ServiceProjectEnabledStatus.ENABLED,
    owner: email
  }
}
